.content{
    padding: 80px;
    padding-left: 80px;
    background: #232b2b;
    color: white;
}

.line{
    height: 1px;
    width: 100%;
    background: red;
}

.pictureBox{
    width: 100%;
}


.pictureBox img{
    object-fit: contain;
    object-position: center;
    width: 100%;
}

.picture{
    padding-top: 30px;
}


.box{
    padding-top: 80px;
}

.contentText{
    padding-top: 20px;
}

@media only screen and (max-width: 480px) {

    .content{
        padding: 30px;
    }
  
    .picture{
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .box{
        padding-top: 30px;
    }
   
  }

  @media only screen and (min-width: 680px) {

    .pictureBox{
        margin-bottom: 300px;
    }
    
   
  }