.container{
    background: #232b2b;
    color: white;

    text-align: center;
    height: 100vh;
    padding-top: 30px;
} 

.contentBoxText{
    padding: 20px 50px;
    text-align: justify;
}

.contentBox h4{
    color: #BB000E;
}

@media only screen and (max-width: 800px) {

    .containerText{
        padding: 10px 20px;
    }

    .contentBoxText{
        padding: 10px 20px;
    }

    
}


@media only screen and (max-width: 480px) {

.container{
    height: 80vh;
}
    
}