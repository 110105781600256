.container{
    padding-bottom: 40px;
    background: #232b2b;
    color: white;
}

.infoBox{
    padding: 50px;
    text-align: center;
}

.textBox{
    font-size: 24px;
    border-top: 1px solid #BB000E;
    margin:10px 0;
}

@media only screen and (max-width: 600px) {
  
    .infoBox{
        padding: 25px 50px;
        
    }

    .textBox{
        font-size: 16px;
    }
}