*{
  font-family: 'Poppins', sans-serif;
}

.accordion-header button{
  background-color: #353839;
  color: white;
  
}

.accordion-body{
  background-color: #1C1C1C;
  color: white;
}

.accordion-button:not(.collapsed) {
  color: #ffffff !important;
  background-color: #353839 !important;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}
