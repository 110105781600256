.logo{
    width: 50px;
    height: 50px;
    display: inline;
}

/* .block{
    display: flex;
    background: #353839;
    color: white;
} */

.social{
    padding: 20px;
}

.socialLink{
    text-align: center;
}

.socialLink a{
   text-decoration: none;
}

.copyright{
    padding: 20px;
    text-align: end;
}

.logoWrapper{
    padding: 10px;
}

.footerText{
    display: inline;
}

.footer{
    width: 100%;
    background: #353839;
    color: white;
}

@media only screen and (max-width: 768px) {
  
    .copyright{
    
        text-align: center;
    }
    
    .logoWrapper{
        text-align: center;
    }
    
}