.container{
    padding: 30px 100px;

    background: #232b2b;
    color: white;
}

.advantagesTitle{
    text-align: center;
    margin: 20px 0;
    color: #BB000E;
    position: relative;
    z-index: 2;
}

.contentBox{
    padding: 20px;
    height: 100%;
}

.contentBox h4{
    color: #BB000E;
}

.contentBoxText{
 margin-top: 10px;
 padding: 0 50px;
 text-align: justify;
}

.titleDecoration{
    content: ' ';
    height: 10px;
    width: 200px;
    background: #4DB6AC;
    position: absolute;
    left: 35%;
    top: 55%;
    z-index: -1;
}

.contentLine{
    content: ' ';
    height: 6px;
    width: 70%;
    background: #BB000E;
    border-top-right-radius: 6px;
    margin-left: 50px;
}

@media only screen and (max-width: 480px) {

    .contentBoxText{
        padding: 0;

    }
}