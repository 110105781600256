.box{
    height: 100px;
    width: 100%;
    background: #353839;
    display: flex;
    align-items: center;
    border-top: 2px solid #BB000E;
    border-bottom: 2px solid #BB000E;
    color: white;
    align-content: center;
}


.boxText{
    font-size: 34px;
}

@media only screen and (max-width: 600px) {
    .boxText{
        font-size: 24px;
    }
}