.marker {

    transform: perspective(40px) rotateX(20deg) rotateZ(-45deg);

    transform-origin: 50% 50%;

    border-radius: 50% 50% 50% 0;

    padding: 0 3px 3px 0;

    width: 30px;

    height: 30px;

    background: #ed1f34;

    position: absolute;

    left: 50%;

    top: 50%;

    margin: -2.2em 0 0 -1.3em;

    -webkit-box-shadow: -1px 1px 4px rgba(0, 0, 0, .5);

    -moz-box-shadow: -1px 1px 4px rgba(0, 0, 0, .5);

    box-shadow: -1px 1px 4px rgba(0, 0, 0, .5);

  }

 

  .marker:after {

    content: '';

    width: 1em;

    height: 1em;

    margin: 1em 0 0 .7em;

    background: #ffffff;

    position: absolute;

    border-radius: 50%;

      -moz-box-shadow: 0 0 10px rgba(0, 0, 0, .5);

    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, .5);

    box-shadow: 0 0 10px rgba(0, 0, 0, .5);

    -moz-box-shadow: inset -2px 2px 4px hsla(0, 0, 0, .5);

    -webkit-box-shadow: inset -2px 2px 4px hsla(0, 0, 0, .5);

    box-shadow: inset -2px 2px 4px hsla(0, 0, 0, .5);

  }

  .beacon {

    position: absolute;

    top: 50%;

    left: 50%;

    height: 3em;

    width: 3em;

    border-radius: 50%;

    -webkit-transform: translateX(-50%);

    -o-transform: translateX(-50%);

    -moz-transform: translateX(-50%);

    -ms-transform: translateX(-50%);

    transform: translateX(-50%);

    z-index: -1

  }

 

  .beacon:before,

  .beacon:after {

    position: absolute;

    content: "";

    height: 3em;

    width: 3em;

    left: 0;

    top: 0;

    background-color: transparent;

    border-radius: 50%;

    box-shadow: 0 0 0 3px #ed1f34;

    -webkit-animation: active 2s infinite linear;

    -moz-animation: active 2s infinite linear;

    animation: active 2s infinite linear;

  }

 

  .beacon:after {

    -moz-animation-delay: 1s;

    -webkit-animation-delay: 1s;

    animation-delay: 1s;

  }

 

  @-webkit-keyframes active {

    0% {

      -webkit-transform: scale(.1);

      opacity: 1;

    }

    100% {

      -webkit-transform: scale(2.5);

      opacity: 0;

    }

  }

 

  @keyframes active {

    0% {

      transform: scale(.1);

      transform: box-shadow(0px 0px 0px 1px #ed1f34);

      opacity: 1;

    }

    100% {

      transform: scale(2.5);

      opacity: 0;

    }

  }