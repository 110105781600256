.card h1 {
    color: #88B04B;
    font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
    font-weight: 900;
    font-size: 40px;
    margin-bottom: 10px;
  }
  .card p {
    color: #404F5E;
    font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
    font-size:20px;
    margin: 0;
  }
.card i {
  color: #9ABC66;
  font-size: 100px;
  line-height: 200px;
  margin-left:-15px;
}
.card {
  background: white;
  padding: 60px;
  border-radius: 4px;
  box-shadow: 0 2px 3px #C8D0D8;
  display: inline-block;
  margin: 0 auto;
}

.subcard{
    border-radius:200px; height:200px; width:200px; background: #F8FAF5; margin:0 auto;
}

.wrapper{
   height: 100vh;
   padding-top: 10%;
   background: #232b2b;
}

.sendButton {
    margin-top: 15px;
    height: 34px;
    width: 100%;
    overflow: hidden;
    transition: all .2s ease-in-out;
  }

  .sendText {
    display: block;
    font: 700 12px 'Lato', sans-serif;
    letter-spacing: 2px;
  }