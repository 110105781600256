.container{
    background: #232b2b;
    color: white;
}

.box{
    padding: 80px;
}



@media only screen and (max-width: 600px) {
  
    .box{
        padding: 10px;
    }
    
}


@media only screen and (max-width: 768px) {
  
    .box{
        padding: 10px;
    }
    
}