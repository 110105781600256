.container{
    background: #232b2b;
    color: white;
    padding: 50px 80px;
    text-align: center;
} 

.containerText{
    padding: 20px 80px;
    text-align: justify;
}



@media only screen and (max-width: 800px) {
  
    .container{
        padding: 20px 30px;
        padding-top: 60px;
    } 

    .containerText{
        padding: 10px 20px;
    }
    
}