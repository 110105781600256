.labelText{
    color: white;
    margin-top: 5px;
  }

  .container{
    background: #232b2b;
    padding-top: 30px;
    padding-bottom: 50px;
    color: white;
  }

  .sendButton {
    margin-top: 15px;
    height: 34px;
    width: 100%;
    overflow: hidden;
    transition: all .2s ease-in-out;
  }

  .sendText {
    display: block;
    margin-top: 10px;
    font: 700 12px 'Lato', sans-serif;
    letter-spacing: 2px;
  }

  .paper{
    width: 20px;
  }
  
  .altSendButton:hover {
    transform: translate3d(0px, -29px, 0px);
  }