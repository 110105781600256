.selected{
    color:red;
    border-bottom: 1px solid black;
}

.coverpicture{
    animation: animName 2s linear infinite;
}
.coverpicture img{
    object-fit: cover;
    width: 100%;
}

.picture{
    width: 40px;
    height: 50px;
    display: inline;
    margin-bottom: 5px;

}


button .navbar-toggler[aria-controls="responsive-navbar-nav"] .navbar-toggler-icon {
    width: 1em;
    height: 1em;
}

.navbar-toggler:focus {
    box-shadow: none;
}

@keyframes animName {
    0%{
       transform: rotateY(0deg);
      }
   100%{
       transform: rotateY(360deg);
      }
   }


.brandheadname{
    font-weight: bold;
    text-align: center;
}

.brandsubname{
    font-size: 10px;    
    padding-left: 1px;
}

@media only screen and (max-width: 600px) {
  
    .navContent{
        display: flex;
        align-content: center;
        width: 100%;
    }

    .mobile{
        display: flex;
        justify-content: center;
        width: 100%;
    }
}

.selectlanguage_mobile{
    display:none;
 }

 .selectlanguage_desktop{
    display:block;

 }


 @media only screen and (min-width: 1000px) {

    .selectlanguage_mobile{
        display:none;
     }
    
     .selectlanguage_desktop{
        display:block;
    
     }

     .testContent{
        display: flex;
    }
   
} 

@media only screen and (max-width: 480px) {

    .selectlanguage_mobile{
        display:block;
     }
    

     .selectlanguage_desktop{
        display:none;
     }

     .testContent{
        display: flex;
        width: 100%;
        justify-content: space-evenly;
    }

    .mobileblock{
        padding: 0;
    }

    .brandheadname{
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        padding-top: 7px;
    }


}