
.coverBox{
    padding: 40px 80px;
    background: #232b2b;
    color: white;
}

.numberBox{
   padding: 50px 0;
    background: #4DB6AC;
    text-align: center;
    color: white;
    font-size: 24px;
    border-radius: 6px;
    box-shadow: rgba(77, 182, 172, 0.4) 5px 5px, rgba(77, 182, 172, 0.3) 10px 10px, rgba(77, 182, 172, 0.2) 15px 15px, rgba(77, 182, 172, 0.1) 20px 20px, rgba(77, 182, 172, 0.05) 25px 25px;
}

@media only screen and (max-width: 600px) {

    .coverBox{
        padding: 40px 40px;
        background: #232b2b;
        color: white;
    }
    
  
    .numberBox{
        margin-top: 10px ;
        margin-bottom: 10px;
    }
}


@media only screen and (max-width: 768px) {

  
    .numberBox{
        margin-top: 10px ;
        margin-bottom: 10px;
    }
}