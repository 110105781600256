
.language{
    margin-right: 15px;
 }


 @media only screen and (max-width: 480px) {

    .language{
        margin-right: 10px;
        margin-top: 10px;
        padding: 0;
     }

     .language button{
      height: 100%;
      padding: 2px 4px;
     }
    
   
}