.container{
    background: #232b2b;
    color: white;

    text-align: center;
} 

.containerText{
    padding: 50px 80px;
    text-align: justify;
}

.abbat{
    color: #BB000E;
    font-size: large;
}

@media only screen and (max-width: 800px) {
  


    .containerText{
        padding: 10px 20px;
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .contentBoxText{
        padding: 10px 20px;
    }

    
}